body {
  background-color: #eee;
  margin: 0;
}

.containerWithGutters {
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
}

.appBar {
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  height: 96px;
  position: fixed;
  width: 100%;
  z-index: 500;
}

.appBarContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.logo {
  box-sizing: border-box;
  height: 100%;
  width: 132px;
  padding: 24px 0;
}

.registerLinkButton {
  background-color: #ea1d63;
  color: #fff;
  font-family: Gilroy-SemiBold, sans-serif;
  height: 48px;
  border-radius: 200px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  width: 200px;
}

.registerLinkButton:hover {
  background-color: #cf1655;
}

.bodyWrapper {
  padding-top: 96px;
}
