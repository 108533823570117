.UnauthorizedCheckStatusResultCard-card {
  /* White / High Emphasis */

  background: #ffffff;
  /* Gray / Divider */

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  /* If you change the card height then update it in CheckStatusPageBody */
  height: 180px;
  margin-bottom: 24px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 0 35px;
}

.UnauthorizedCheckStatusResultCard-card svg {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.UnauthorizedCheckStatusResultCard-textcontainer {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 100%;
}

.UnauthorizedCheckStatusResultCard-header {
  display: flex;
  flex-direction: row;
}

.UnauthorizedCheckStatusResultCard-text-detail {
  margin: 0;
  font-family: Gilroy-Medium, sans-serif;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Font / Secondary */

  color: #5e6572;
}

.UnauthorizedCheckStatusResultCard-text-table {
  display: flex;
  margin: 0;
  font-family: Gilroy-Medium, sans-serif;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Font / Main */

  color: #212936;
}

.UnauthorizedCheckStatusResultCard-text-column {
  display: flex;
  flex-direction: column;
  margin-right: 70px;
}

.UnauthorizedCheckStatusResultCard-text-columnheader {
  font-family: Gilroy-Regular, sans-serif;
  font-size: 15px;
  min-width: max-content;
  line-height: 32px;
  /* identical to box height, or 213% */

  /* Font / Secondary */

  color: #5e6572;
}

.UnauthorizedCheckStatusResultCard-text-columndata {
  min-width: max-content;
}

.UnauthorizedCheckStatusResultCard-text-columndata-dynamic {
  min-width: auto;
}

.UnauthorizedCheckStatusResultCard-title {
  margin: 0 0 12px 0;
  font-family: Gilroy-Bold, sans-serif;
  font-size: 24px;

  display: flex;
  align-items: center;

  /* Font / Light */

  color: #6f7580;
}

.UnauthorizedCheckStatusResultCard-subtitle {
  font-family: Gilroy-Medium, sans-serif;
}

.UnauthorizedCheckStatusResultCard-warning {
  color: #cb7f00;
}

.UnauthorizedCheckStatusResultCard-success {
  color: #04b86c;
}
.UnauthorizedCheckStatusResultCard-disabled {
  color: #6f7580;
}
.UnauthorizedCheckStatusResultCard-error {
  color: #e53935;
}

.UnauthorizedCheckStatusResultCard-print-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: auto;
  height: 38px;
  width: 144px;

  background-color: #fff; /* white */
  border: none; /* Primary/Main */
  cursor: pointer;

  color: #039ec3; /* Primary/Main */
  font-family: Gilroy-Medium, sans-serif;
  font-size: 16px;
  line-height: 19px;
}

.UnauthorizedCheckStatusResultCard-print-button:focus {
  outline: none;
}

.UnauthorizedCheckStatusResultCard-print-button .UnauthorizedCheckStatusResultCard-print-icon {
  margin: 0 8px 0 0;
  height: 20px;
}

.UnauthorizedCheckStatusResultCard-phone-contact {
  text-decoration: none;
  color: #039ec3;
}
