/*
 * Font definitions for the /check_status page in the authtool
 * This page doesn't use our typical styleguide with material-ui which has its fonts defined in themeFont.css
 */

@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("webFonts/Gilroy-SemiBold/font.woff2") format("woff2"),
    url("webFonts/Gilroy-SemiBold/font.woff") format("woff");
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("webFonts/Gilroy-Bold/font.woff2") format("woff2"), url("webFonts/Gilroy-Bold/font.woff") format("woff");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("webFonts/Gilroy-Medium/font.woff2") format("woff2"), url("webFonts/Gilroy-Medium/font.woff") format("woff");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("webFonts/Gilroy-Regular/font.woff2") format("woff2"),
    url("webFonts/Gilroy-Regular/font.woff") format("woff");
    font-style: normal;
    font-weight: 400;
}