.statusBodyTitle {
  border-bottom: 1px solid #d7d5d5;
  color: #6d6f71;
  font-family: Gilroy-Bold, sans-serif;
  font-size: 45px;
  line-height: 59px;
  padding: 48px 0;
  margin: 0;
}

.statusBodyResults {
  margin-top: 40px;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.statusBodyResults.isVisible {
  opacity: 1;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1), opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
}
