.registerMessageContainer {
  display: flex;
  flex-direction: column;
  max-width: 608px;
  margin: 56px auto 0;
  text-align: center;
}

.registerMessageHeader {
  align-items: center;
  align-self: center;
  color: #04b86c;
  display: flex;
  font-family: Gilroy-Bold, sans-serif;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}

.registerMessageIcon {
  margin-right: 4px;
  padding-bottom: 2px;
}

.registerMessageLink {
  color: #039ec3;
  text-decoration: none;
}

.registerMessageBody {
  color: #5e6572;
  font-family: Gilroy-Medium, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
