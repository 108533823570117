.searchFieldsContainer {
  width: 100%;
  border-bottom: 1px solid #d7d5d5;
  display: flex;
  height: 425px;
}

.searchFieldSectionLeft {
  width: 50%;
  height: 100%;
  padding-right: 112px;
  border-right: 1px solid #d7d5d5;
}

.searchFieldSectionRight {
  width: 50%;
  padding-left: 112px;
}

.searchFieldHeader {
  font-family: Gilroy-Bold, sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: #6d6f71;
  margin: 32px 0 24px;
}

.searchFieldLabel {
  color: #6d6f71;
  font-family: Gilroy-Regular, sans-serif;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 4px;
}

.searchFieldInput {
  padding: 16px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  font-family: Gilroy-Regular, sans-serif;
  font-size: 16px;
  line-height: 32px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.searchFieldInput:focus {
  border-color: #039ec3;
  outline: none;
}

.searchFieldInput:hover {
  border-color: #039ec3;
}

.searchFieldInput::placeholder {
  color: #c2c2c2;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.searchButton {
  background-color: #039ec3;
  color: #fff;
  font-family: Gilroy-SemiBold, sans-serif;
  height: 48px;
  border-radius: 200px;
  width: 200px;
  border: none;
  cursor: pointer;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.searchButton:focus,
.resetButton:focus {
  outline: none;
}

.searchButton:hover {
  background-color: #0291b2;
}

.searchButton:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.resetButton {
  cursor: pointer;
  border: none;
  font-family: Gilroy-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #039ec3;
  padding: 0;
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.resetButton:hover {
  color: #0291b2;
}

.resetButton:disabled {
  cursor: default;
  opacity: 0;
}

.searchFieldInputContainer {
  position: relative;
}

.searchFieldsValidateIcon {
  position: absolute;
  top: 22px;
  right: 25px;
  height: 17px;
  color: #04b86c; /* success / base */
}
